@mixin truncate($lines, $lh: 1.4, $spacer: 0) {
  line-height: $lh;
  max-height: $lh * $lines + $spacer + em;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
