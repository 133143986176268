@import "./styles/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}

.sidebar a {
  color: #000;
  text-decoration: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.validation-error {
  font-size: 13px;
  color: $error-color;
}

.product {
  margin-bottom: 10px;
}

.product:last-child {
  margin: 0;
}

.received {
  background-color: #90ee90;
}

.pending {
  background-color: #d3d3d3;
}

.text-capitalize {
  text-transform: capitalize;
}
