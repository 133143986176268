@import "./styles/variables.scss";
@import "./styles/mixins.scss";

.singleLineTruncate {
  display: block;
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiLineTruncate {
  height: 40px;
  @include truncate(2);
}

// stripe
.StripeElement {
  margin: 8px 0 16px 0;
  padding: 16px 14px;
  border-radius: 4px;
  &.StripeElement--complete {
    background: transparent;
  }
}

.stripeLighthThemeStyles label {
  color: #010810;
  font-weight: 300;
  font-size: 16px;
}

.stripeLighthThemeStyles .StripeElement {
  border: 1px solid rgba(0, 0, 0, 0.23);
  &.StripeElement--complete {
    color: #010810;
  }
}

.stripeDarkThemeStyles label {
  color: #c4d1ff;
  font-weight: 300;
  font-size: 16px;
}

.stripeDarkThemeStyles .StripeElement {
  border: 1px solid rgba(196, 209, 255, 0.23);
  &.StripeElement--complete {
    color: #c4d1ff;
  }
}

// Rich text

.quill {
  .ql-clean {
    display: none !important;
  }
  &.dark-theme-quill {
    .ql-toolbar.ql-snow {
      border: 1px solid rgba(196, 209, 255, 0.23);
      .ql-picker-label.ql-active {
        color: #53e79c;
      }
      .ql-picker-label {
        border: 1px solid transparent !important;
      }
      .ql-picker-label:hover {
        color: #53e79c;
      }
      .ql-picker.ql-header {
        color: #c4d1ff;
      }
      & .ql-picker.ql-expanded .ql-picker-options {
        background: #010810;
        border-color: #010810;
      }
      & .ql-fill {
        stroke: #c4d1ff;
      }
      & .ql-picker-label:hover .ql-stroke {
        stroke: #53e79c;
      }
      & button:hover {
        color: #53e79c;
        .ql-stroke {
          stroke: #53e79c;
        }
        .ql-fill {
          stroke: #53e79c;
        }
      }
      & .ql-stroke {
        stroke: #c4d1ff;
      }
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border: 1px solid rgba(196, 209, 255, 0.23);
      .ql-editor {
        color: #c4d1ff;
        padding: 16px 14px;
        &::before {
          color: rgba(196, 209, 255, 0.5);
          font-style: normal;
        }
      }
    }
  }
  &.light-theme-quill {
    .ql-toolbar.ql-snow {
      border: 1px solid rgba(0, 0, 0, 0.23);
      .ql-picker-label.ql-active {
        color: #3c67ff;
      }
      .ql-picker-label {
        border: 1px solid transparent !important;
      }
      .ql-picker-label:hover {
        color: #3c67ff;
      }
      .ql-picker.ql-header {
        color: rgba(1, 8, 16, 0.87);
      }
      & .ql-picker.ql-expanded .ql-picker-options {
        background: #fff;
        border-color: #fff;
      }
      & .ql-fill {
        stroke: rgba(1, 8, 16, 0.87);
      }
      & .ql-picker-label:hover .ql-stroke {
        stroke: #3c67ff;
      }
      & button:hover {
        color: #3c67ff;
        .ql-stroke {
          stroke: #3c67ff;
        }
        .ql-fill {
          stroke: #3c67ff;
        }
      }
      & .ql-stroke {
        stroke: rgba(1, 8, 16, 0.87);
      }
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border: 1px solid rgba(0, 0, 0, 0.23);
      .ql-editor {
        color: rgba(1, 8, 16, 0.87);
        padding: 16px 14px;
        &::before {
          color: rgba(1, 8, 16, 0.87);
          font-style: normal;
        }
      }
    }
  }
}
